<template>
  <div class="create-template-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(createTemplate)">
        <b-form-group>

          <!-- Set Title -->
          <b-input-group class="mb-2 d-flex flex-column">
            <div>
              <label>Title</label>
              <validation-provider
                v-slot="validationContext"
                name="Template title"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="template.title"
                  placeholder="Enter the template title..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </b-input-group>

          <!-- Set Category -->
          <label>Category</label>
          <validation-provider
            v-slot="validationContext"
            name="Template category"
            :rules="{ required:true }"
          >
            <b-form-select v-model="template.template_category_id" class="mb-2">
              <b-form-select-option :value="null" disabled>Select a category</b-form-select-option>
              <b-form-select-option
                v-for="category in categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>


          <b-input-group class="d-flex flex-row-reverse">
            <b-btn variant="primary" type="submit" :disabled="invalid || submitting">Create Template</b-btn>
            <b-btn class="d-inline mr-2" @click="$emit('close')">Cancel</b-btn>
          </b-input-group>
        </b-form-group>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import helperService from '@/services/HelperService';
import purpleDocsTemplateService from "@/services/PurpleDocsTemplateService";
import PurpleDocsCategoryService from "@/services/PurpleDocsCategoryService";

export default {
  name: 'CreateTemplateModal',
  data() {
    return {
      submitting: false,
      template: {
        title: '',
        template_category_id: null,
      },
      categories: [],
    }
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async createTemplate() {
      this.submitting = true
      try {
        await purpleDocsTemplateService.createTemplate(this.template)
        helperService.showNotfySuccess(this.$toast, 'The template has been created successfully.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while creating the template. Please refresh and try again.')
      } finally {
        this.submitting = false
        this.$emit('refresh')
      }
    },
    async getCategories() {
      try {
        const { data } = await PurpleDocsCategoryService.listCategories()
        this.categories = data.data
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not fetch categories, please refresh and try again')
      }
    },
  },
}
</script>

<style scoped>

</style>
