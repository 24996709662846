<template>
  <div class="update-template-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(updateTemplate)">
        <b-form-group>

          <!-- Set Title -->
          <b-input-group class="mb-2 d-flex flex-column">
            <div>
              <label>Title</label>
              <validation-provider
                v-slot="validationContext"
                name="Template title"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="updatedTemplate.title"
                  placeholder="Enter the template title..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </b-input-group>

          <!-- Set Category -->
          <label>Category</label>
          <validation-provider
            v-slot="validationContext"
            name="Template category"
            :rules="{ required:true }"
          >
            <b-form-select v-model="updatedTemplate.template_category_id" class="mb-2">
              <b-form-select-option :value="null" disabled>Select a category</b-form-select-option>
              <b-form-select-option
                v-for="category in categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>

          <!-- Set Enterprise -->
          <label>Enterprise</label>
          <b-input-group-append class="mb-2">
            <model-list-select v-model="updatedTemplate.enterprise_uid"
                               :list="displayEnterprises"
                               :class="{'disabled': displayEnterprises.length === 0}"
                               option-value="uid"
                               option-text="display_name"
                               placeholder="All Enterprises"
            />

          </b-input-group-append>

          <!-- Set Published -->
          <label>Status</label>
          <b-form-select v-model="updatedTemplate.is_published" class="mb-2">
            <b-form-select-option :value="true">Published</b-form-select-option>
            <b-form-select-option :value="false">Draft</b-form-select-option>
          </b-form-select>

          <b-input-group class="d-flex flex-row-reverse mt-4">
            <b-btn variant="primary" type="submit" :disabled="invalid || submitting">Update Template</b-btn>
            <b-btn class="d-inline mr-2" @click="$emit('close')">Cancel</b-btn>
          </b-input-group>
        </b-form-group>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import helperService from '@/services/HelperService';
import purpleDocsTemplateService from "@/services/PurpleDocsTemplateService";
import PurpleDocsCategoryService from "@/services/PurpleDocsCategoryService";
import EnterpriseService from "@/services/EnterpriseService";
import {ModelListSelect} from "vue-search-select";

export default {
  name: 'UpdateTemplateModal',
  components: {
    ModelListSelect
  },
  props: {
    template: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      submitting: false,
      updatedTemplate: {
        title: '',
        template_category_id: null,
        enterprise_uid: null,
        is_published: false,
      },
      categories: [],
      enterprises: [],
    }
  },
  computed: {
    displayEnterprises() {
      return [{ uid: "", display_name: "All Enterprises" }, ...this.enterprises];
    }
  },
  mounted() {
    this.getCategories()
    this.getEnterprises()
    this.updatedTemplate = { ...this.template }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async updateTemplate() {
      this.submitting = true
      try {
        const updated = this.updatedTemplate
        if (updated.enterprise_uid.toLowerCase() === "all enterprises") {
          updated.enterprise_uid = ''
        }
        await purpleDocsTemplateService.updateTemplate(this.template.id, updated)
        helperService.showNotfySuccess(this.$toast, 'The template has been updated successfully.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while updating the template. Please refresh and try again.')
      } finally {
        this.submitting = false
        this.$emit('refresh')
      }
    },
    async getCategories() {
      try {
        const { data } = await PurpleDocsCategoryService.listCategories()
        this.categories = data.data
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not fetch categories, please refresh and try again')
      }
    },
    async getEnterprises() {
      try {
        const { data } = await EnterpriseService.getEnterprises({ page_size: 10000 })
        this.enterprises = data.data
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not fetch enterprises, please refresh and try again')
      }
    },
  },
}
</script>

<style scoped>

</style>
